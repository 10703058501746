import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import form_helper from '../../../../../libs/form-lib';
import Icon from '../../../../../components/cmp_icon';
import CMP_BREADCRUMB from '../../../../../components/cmp_breadcrumb/cmp_breadcrumb';
import { FORM_DETAILSGROUP, FORM_INPUT } from '../../../../../components/cmp_form/cmp_form';



export default function INDIVIDUAL_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();

    const [ var_organization_name, set_organization_name ] = useState('');
    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_individual, set_individual ] = useState(null);
    const [ var_working_individual, set_working_individual ] = useState({});
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        load_organization_name();
        populate_record();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function load_organization_name() {
        let organization = await API_get_organization();
        set_organization_name(organization.name);
    }

    async function populate_record() {
        let record = await API_get_org_individual();
        record.id = params.individualid;
        record.organization_id = params.organizationid;
        set_individual(record);
        set_mode('VIEW');
    }

    async function save_email(working_individual) {
        set_processing(true);
        try {
            let result = await API_put_org_individual_email(working_individual);
            if (result.success) {
                set_individual({...var_individual, ...working_individual});
                set_mode('VIEW');
            } else {
                set_save_error(result.message);
            }
        } catch (exception) {
            console.log(exception);
            set_save_error('An error has occurred');
        } finally {
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization() {
        return API.get('alpha-org', '/get-organization/' + params.organizationid);
    }

    function API_get_org_individual() {
        return API.get('alpha-org', '/get-org-individual/' + params.organizationid + '/' + params.individualid);
    }

    function API_put_org_individual_email(working_individual) {
        return API.put('alpha-org', '/put-org-individual-email', {
            body: working_individual
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_editemail() {
        let { organization_id, id, email } = var_individual;
        set_working_individual({ organization_id, id, email });
        set_errors([]);
        set_save_error(null);
        set_mode('EDITEMAIL');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_input(event) {
        let working_individual = {...var_working_individual};
        working_individual[event.target.name] = event.target.value;
        set_working_individual(working_individual);
    }

    function onClick_saveemail() {
        if (var_processing) return;

        let individual = {...var_working_individual};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(individual.email)) {
            errors.push({property: 'email', description: 'Please enter the email of the individual'});
        } else if (!form_helper.validate_email_address(individual.email, false)) {
            errors.push({property: 'email', description: 'Please enter a valid email address'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(individual, 'email');
        set_working_individual(individual);
        save_email(individual);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='page'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='organization'
                    breadcrumb_elements={[{ path: '/organizations', text: 'Organizations' }, { path: '/organizations/' + params.organizationid, text: var_organization_name }, { path: '/organizations/' + params.organizationid + '/individuals', text: 'Individuals' }]}
                    current_page_text={var_individual ? (var_individual.firstname + ' ' + var_individual.lastname) : ''}
                />
                <h1 className='display--sm-regular'>{var_individual ? (var_individual.firstname + ' ' + var_individual.lastname) : 'Individual Details'}</h1>

            </div>

            <div className='card rounded-lg shadow-sm'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>Individual Details</div>
                    </div>
                    <div className='card__header__right'>
                        {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={() => onClick_editemail()}>Edit</Button>
                        }
                    </div>
                </div>

                <div className='card__content'>
                    <Form>
                        {
                            var_mode === 'VIEW' ?
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <FORM_DETAILSGROUP label='First name' value={var_individual.firstname} />
                                <FORM_DETAILSGROUP label='Last name' value={var_individual.lastname} />
                                <FORM_DETAILSGROUP label='Title' value={var_individual.job_title} />
                                <FORM_DETAILSGROUP label='Email' value={var_individual.email} />
                                <FORM_DETAILSGROUP label='Permission level' value={var_individual.permission} />
                            </div>
                            : var_mode === 'EDITEMAIL' ?
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {var_save_error &&
                                    <Message error
                                        header={var_save_error}
                                    />
                                }
                                {var_errors.length > 0 &&
                                    <Message error
                                        icon={<Icon name='error' className='icon' />}
                                        header='There are some errors with your inputs'
                                    />
                                }

                                <FORM_INPUT
                                    description='Changing the email will change their login details.  Please coordinate with the individual when changing.'
                                    property='email'
                                    label='Email'
                                    value={var_working_individual.email}
                                    onChange={onChange_input}
                                    placeholder='example@email.com'
                                    maxLength={100}
                                    disabled={false}
                                    errors={var_errors}
                                />

                                <div className='card__footer'>
                                    <Button className='primary' onClick={onClick_saveemail}>Save</Button>
                                    <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                                </div>
                            </div>
                            : null
                        }
                    </Form>
                </div>

            </div>
        </div>
    );

};