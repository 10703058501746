import React, { useState, useEffect } from 'react';
import API from '../../../../libs/api-lib';
import { Button, Form } from 'semantic-ui-react';
import { CHECKBOX } from "../../../../components/cmp_form/cmp_form";

import './crd_features.css';



export default function CRD_FEATURES() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_features, set_features ] = useState({});
    const [ var_working_features, set_working_features ] = useState({});
    const [ var_processing, set_processing ] = useState(false);

    const feature_list = [
        {feature: 'assignments', name: 'Assignments', info: 'Management of location and projects with features below'},
        {feature: 'dailycheck', name: 'Daily Check', info: 'Create and present a daily questionnaire.',  sub_category: 'assignments'},
        {feature: 'documents', name: 'Documents', info: 'Upload documents to be acknowledged by individuals.', sub_category: 'assignments'},
        {feature: 'guest', name: 'Guest', info: 'Allow guests to join the organization.', sub_category: 'assignments'},
        {feature: 'networks', name: 'Networks', info: 'Allow the organization to invite, or be invited, to a network.', sub_category: 'assignments'},
        {feature: 'r2w', name: 'Onsite', info: 'Allow individuals to declare they are physically on a work site.', sub_category: 'assignments'},
        {feature: 'qraccess', name: 'QR Access', info: 'Enable QR code access for assignments.', sub_category: 'assignments'},
        {feature: 'credentials', name: 'Credentials', info: 'View an individual\'s credentials and assign requirements.'},
        {feature: 'issuer', name: 'Issuer', info: 'Enable the organization to issue credentials.'},
        //{feature: 'wallet', name: 'Org Wallet', info: 'View an organization\'s credentials.'},
        {feature: 'reports', name: 'Reports', info: 'View an organization\'s data analytics reports.'},
        {feature: 'requirements', name: 'Requirements', info: 'Manage credential compliance with requirements and packages'},
        {feature: 'verifiable_credentials', name: 'Verifiable Credentials', info: 'Allow the organization to issue verifiable credentials.'}
    ];



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        set_features(await API_get_features());
        set_mode('VIEW');
    }

    async function save_record() {
        set_processing(true);
        try {
            await API_put_features();
            set_features(var_working_features.map(item => ({...item})));
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_features() {
        return API.get('alpha-admin', '/get-features');
    }

    function API_put_features() {
        return API.put('alpha-admin', '/put-features', {
            body: var_working_features
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_working_features(var_features.map(item => ({...item})));
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_checkbox(feature_name) {
        let working_features = var_working_features.map(item => ({...item}));
        let feature = working_features.find(item => item.name === feature_name);
        feature.status = feature.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        if (feature.status === 'INACTIVE') {
            for (let index in feature_list) {
                if (feature_list[index].sub_category === feature_name) {
                    let feature_change = working_features.find(item => item.name === feature_list[index].feature);
                    feature_change.status = 'INACTIVE'
                }
            }
        }
        set_working_features(working_features);
    }

    function onClick_save() {
        if (var_processing) return;

        save_record();
    }



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Product Features</div>
                    <div className='text--sm-regular'>Enable or disable the features in Alpha. Selections made here will impact&nbsp;
                        <div className='text--sm-bold'>all clients</div>&nbsp;using Alpha.</div>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                    <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                <Form>
                    {var_mode !== 'LOADING' &&
                        feature_list.map(item => render_feature(var_mode === 'VIEW' ? var_features : var_working_features, item, var_mode === 'VIEW', item.sub_category))
                    }
                    {var_mode === 'EDIT' &&
                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>Save</Button>
                            <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                        </div>
                    }
                </Form>
            </div>

        </>
    );

    function render_feature(alpha_features, feature, disabled, sub_category) {
        let active = alpha_features.find(item => item.name === feature.feature).status === 'ACTIVE';
        return (
            <div className='detailsgroup descriptive' key={'feature-' + feature.feature}>
                <Form.Field className={(sub_category ? 'indent' : '')}>
                    <CHECKBOX name={feature.feature}
                              label={feature.name}
                              checked={active}
                              onChange={() => onChange_checkbox(feature.feature)}
                              disabled={disabled} />
                </Form.Field>
                <div>{feature.info}</div>
            </div>
        );
    }
};
