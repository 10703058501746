import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../../libs/api-lib';
import { Button, Message } from 'semantic-ui-react';
import Icon from '../../../components/cmp_icon';
import Processing from '../../../components/cmp_processing';
import CMP_BREADCRUMB from '../../../components/cmp_breadcrumb/cmp_breadcrumb';

import CRD_DETAILS from './crd_details/crd_details';
import CRD_LOCATION from './crd_location/crd_location';
import CRD_SUPERADMIN from './crd_superadmin/crd_superadmin';
import CRD_FEATURES from './crd_features/crd_features';

import './organization_add.css';



export default function ORGANIZATION_ADD() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const [ var_organization, set_organization ] = useState({location: {default_org: 'YES'}, admin: {}, demoaccount: 'NO', credentials_ext: 'NO'});
    const [ var_stepstatus, set_stepstatus ] = useState([
        { step: 1, status: 'CURRENT' },
        { step: 2, status: 'INCOMPLETE' },
        { step: 3, status: 'INCOMPLETE' },
        { step: 4, status: 'INCOMPLETE' },
    ]);
    const [ var_formstatus, set_formstatus ] = useState('READY');
    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_formstatus === 'CHECKING LOGO UPLOAD') {
            set_processing(true);
        } else if (var_formstatus === 'NO LOGO UPLOAD' || var_formstatus === 'LOGO UPLOAD COMPLETE') {
            save_organization();
        } else if (var_formstatus === 'LOGO UPLOAD FAILED') {
            set_save_error(true);
            set_processing(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_formstatus]);



    //  functions ------------------------------------------------------------------------------------------------------

    async function save_organization() {
        set_processing(true);
        set_save_error(false);
        try {
            let new_organization_id = await API_post_organization();
            set_processing(false);
            navigate('/organizations/' + new_organization_id);
        } catch (exception) {
            console.log(exception);
            set_save_error(true);
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_organization() {
        return API.post('alpha-org', '/post-organization', {
            body: var_organization
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_nextstep() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let stepstatus = var_stepstatus.map(item => ({...item}));
        let currentstep = stepstatus.find(item => item.status === 'CURRENT');
        if (currentstep.step === 3 && var_organization.assignments === 'NO' || currentstep.step === 4) {
            set_formstatus('CHECKING LOGO UPLOAD');
        } else {
            currentstep.status = 'COMPLETE';
            stepstatus.find(item => item.step === currentstep.step + 1).status = 'CURRENT';
            set_stepstatus(stepstatus);
        }
    }

    function onClick_editstep(step) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let stepstatus = var_stepstatus.map(item => ({...item}));
        stepstatus.find(item => item.status === 'CURRENT').status = 'INCOMPLETE';
        stepstatus.find(item => item.step === step).status = 'CURRENT';
        stepstatus.filter(item => item.step > step && item.status === 'COMPLETE').forEach(item => item.status = 'COMPLETE-PENDING');
        set_stepstatus(stepstatus);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='page' id='organizations_add'>

                <div className='page__header page__header--background3'>
                    <CMP_BREADCRUMB
                        icon='organization'
                        breadcrumb_elements={[{ path: '/organizations', text: 'Organizations' }]}
                        current_page_text='Add Organization'
                    />
                    <h1 className='display--sm-regular'>Add Organization</h1>

                </div>

                {var_save_error &&
                    <Message error
                        header='There was a problem saving.  Please try again later'
                    />
                }
                {render_card(1, 'Organization details', 'Set the Name, Address, and Logo for the organization.', CRD_DETAILS, {var_formstatus, set_formstatus})}
                {render_card(2, 'Product settings', 'Configure the product features for the organization.', CRD_FEATURES)}
                {render_card(3, 'Add a Superadmin', 'A Super Admin is a role assigned to an individual at the organization and grants them access to perform all tasks in their Admin console.', CRD_SUPERADMIN)}
                {var_organization.assignments === 'YES' && render_card(4, 'Set default assignment', 'A default assignment is where individuals at the organization will be added to automatically. This can be changed later.', CRD_LOCATION)}
            </div>
            <Processing display={var_processing} />
        </>
    );

    function render_card(step, title, description, Card, other_attributes = {}) {
        let stepstatus = var_stepstatus.find(item => item.step === step);
        return (
            <div className='card rounded-lg shadow-sm'>
                <div className={'card__header ' + (stepstatus.status !== 'CURRENT' ? 'card__header__inactive' : '')}>
                    <div className='card__header__icon'>
                        {stepstatus.status === 'COMPLETE' || stepstatus.status === 'COMPLETE-PENDING' ?
                            <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                            :
                            <div className='card__header__icon__step text--xl-bold icon--dark_gray'>{step}</div>
                        }
                    </div>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{title}</div>
                        {stepstatus.status !== 'COMPLETE' && stepstatus.status !== 'COMPLETE-PENDING' &&
                            <div className='text--sm-regular'>{description}</div>
                        }
                    </div>
                    {stepstatus.status === 'COMPLETE' &&
                        <div className='card__header__right'>
                            <Button className='secondary btn_edit' onClick={() => onClick_editstep(step)}>Edit</Button>
                        </div>
                    }
                </div>

                <div className={'card__content' + (stepstatus.status === 'CURRENT' ? ' edit' : '')}>
                    <Card var_organization={var_organization} set_organization={set_organization} status={stepstatus.status} onClick_nextstep={onClick_nextstep} {...other_attributes} />
                </div>
            </div>
        );
    }
};