import React, { useEffect, useState } from 'react';
import API from '../../../../../libs/api-lib';
import { Button, Message } from 'semantic-ui-react';
import form_helper from '../../../../../libs/form-lib';
import Icon from '../../../../../components/cmp_icon';
import Processing from '../../../../../components/cmp_processing';
import CMP_COPY from '../../../../../components/cmp_copy/cmp_copy';
import CMP_FILEUPLOAD from '../../../../../components/cmp_fileupload/cmp_fileupload';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_SELECT } from '../../../../../components/cmp_form/cmp_form';

import './crd_details.css';



export default function CRD_DETAILS({organization_id, activetab, onChange_organization_name}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_cardstatus, set_cardstatus ] = useState('DISPLAY');
    const [ var_organization, set_organization ] = useState({});
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_errormessage, set_errormessage ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_original_org_name, set_original_org_name ] = useState('');

    const [ var_countries ] = useState([{value: null, text: 'Select country'}, {value: 'Canada', text: 'Canada'}, {value: 'United States', text: 'United States'}]);
    const [ var_regions, set_regions ] = useState([]);
    const [ var_disableregions, set_disableregions ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DETAILS' && var_cardstatus === 'DISPLAY') {
            populate_organization()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activetab, var_cardstatus]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            update_organization();
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_errormessage('There was an error updating the organization');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    useEffect(() => {
        if (var_organization.country === 'Canada') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alberta', text: 'Alberta'},
                {value: 'British Columbia', text: 'British Columbia'},
                {value: 'Manitoba', text: 'Manitoba'},
                {value: 'New Brunswick', text: 'New Brunswick'},
                {value: 'Newfoundland and Labrador', text: 'Newfoundland and Labrador'},
                {value: 'Northwest Territories', text: 'Northwest Territories'},
                {value: 'Nova Scotia', text: 'Nova Scotia'},
                {value: 'Nunavut', text: 'Nunavut'},
                {value: 'Ontario', text: 'Ontario'},
                {value: 'Prince Edward Island', text: 'Prince Edward Island'},
                {value: 'Quebec', text: 'Quebec'},
                {value: 'Saskatchewan', text: 'Saskatchewan'},
                {value: 'Yukon', text: 'Yukon'}]);
            set_disableregions(false);
        } else if (var_organization.country === 'United States') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alabama', text: 'Alabama'},
                {value: 'Alaska', text: 'Alaska'},
                {value: 'Arizona', text: 'Arizona'},
                {value: 'Arkansas', text: 'Arkansas'},
                {value: 'California', text: 'California'},
                {value: 'Colorado', text: 'Colorado'},
                {value: 'Connecticut', text: 'Connecticut'},
                {value: 'Delaware', text: 'Delaware'},
                {value: 'District of Columbia', text: 'District of Columbia'},
                {value: 'Florida', text: 'Florida'},
                {value: 'Georgia', text: 'Georgia'},
                {value: 'Guam', text: 'Guam'},
                {value: 'Hawaii', text: 'Hawaii'},
                {value: 'Idaho', text: 'Idaho'},
                {value: 'Illinois', text: 'Illinois'},
                {value: 'Indiana', text: 'Indiana'},
                {value: 'Iowa', text: 'Iowa'},
                {value: 'Kansas', text: 'Kansas'},
                {value: 'Kentucky', text: 'Kentucky'},
                {value: 'Louisiana', text: 'Louisiana'},
                {value: 'Maine', text: 'Maine'},
                {value: 'Maryland', text: 'Maryland'},
                {value: 'Massachusetts', text: 'Massachusetts'},
                {value: 'Michigan', text: 'Michigan'},
                {value: 'Minnesota', text: 'Minnesota'},
                {value: 'Mississippi', text: 'Mississippi'},
                {value: 'Missouri', text: 'Missouri'},
                {value: 'Montana', text: 'Montana'},
                {value: 'Nebraska', text: 'Nebraska'},
                {value: 'Nevada', text: 'Nevada'},
                {value: 'New Hampshire', text: 'New Hampshire'},
                {value: 'New Jersey', text: 'New Jersey'},
                {value: 'New Mexico', text: 'New Mexico'},
                {value: 'New York', text: 'New York'},
                {value: 'North Carolina', text: 'North Carolina'},
                {value: 'North Dakota', text: 'North Dakota'},
                {value: 'Ohio', text: 'Ohio'},
                {value: 'Oklahoma', text: 'Oklahoma'},
                {value: 'Oregon', text: 'Oregon'},
                {value: 'Pennsylvania', text: 'Pennsylvania'},
                {value: 'Puerto Rico', text: 'Puerto Rico'},
                {value: 'Rhode Island', text: 'Rhode Island'},
                {value: 'South Carolina', text: 'South Carolina'},
                {value: 'South Dakota', text: 'South Dakota'},
                {value: 'Tennessee', text: 'Tennessee'},
                {value: 'Texas', text: 'Texas'},
                {value: 'Utah', text: 'Utah'},
                {value: 'Vermont', text: 'Vermont'},
                {value: 'Virginia', text: 'Virginia'},
                {value: 'Washington', text: 'Washington'},
                {value: 'West Virginia', text: 'West Virginia'},
                {value: 'Wisconsin', text: 'Wisconsin'},
                {value: 'Wyoming', text: 'Wyoming'}]);
            set_disableregions(false);
        } else {
            set_regions([]);
            set_disableregions(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_organization.country]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_organization() {
        try {
            let results = await API_get_organization();
            results.id = organization_id;
            set_organization(results);
            onChange_organization_name(results.name);
            set_original_org_name(results.name)
        } catch (e) {
            console.log(e);
        }
    }

    async function update_organization() {
        set_processing(true);
        try {
            await API_put_organization();
            set_cardstatus('DISPLAY');
            set_processing(false);
        } catch (e) {
            set_errormessage('There was an error updating the organization');
            console.log(e);
            set_processing(false);
        }
    }

    async function check_orgname_exists() {
        return await API_get_orgname_exists() === 'YES' ? true : false
    }

    async function onClick_save() {
        set_errormessage(null);
        let organization = { ...var_organization };
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(organization.name)) {
            errors.push({property: 'name', description: 'Organization name is required.'});
        } else if (organization.name !== var_original_org_name && await check_orgname_exists()) {
            errors.push({property: 'name', description: 'An organization with this name already exists.'});
        }
        if (!form_helper.validate_required_string(organization.country)) {
            errors.push({property: 'country', description: 'Country is required.'});
        }
        if (var_fileupload_status === 'UPLOAD INVALID') {
            errors.push({property: 'logo', description: 'Please select a valid logo file'})
        }
        set_errors(errors);
        if (errors.length > 0) return;

        console.log(var_fileupload_status);

        switch (var_fileupload_status) {
            case 'UPLOAD READY':
            case 'START UPLOAD':
            case 'UPLOAD FAIL':
                set_fileupload_status('START UPLOAD');
                break;
            case 'UPLOAD COMPLETE':
            case 'NONE':
                update_organization();
                break;
            case null:
                update_organization();
                break;
            default:
                break
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization() {
        return API.get('alpha-org', '/get-organization-details/'+ organization_id);
    }

    function API_put_organization() {
        return API.put('alpha-org', '/put-organization-details', { body: var_organization });
    }

    function API_get_orgname_exists() {
        return API.get('alpha-org', '/get-orgname-exists',
        {
            queryStringParameters: {
                name: var_organization.name,
                organization_id: var_organization.id
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_cardstatus('EDIT');
        set_fileupload_status(null);
    }

    function onClick_cancel() {
        set_cardstatus('DISPLAY');
    }

    function onChange_input(event) {
        const organization = { ...var_organization };
        organization[event.target.name] = event.target.value;
        set_organization(organization);

        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_logodetails(details) {
        let organization = { ...var_organization };
        organization.logo = details.filename;
        set_organization(organization);
    }

    function onChange_country(e, { value }) {
        let organization = { ...var_organization };
        if (organization.country !== value) {
            organization.province = null;
        }
        organization.country = value;
        set_organization(organization);
    }

    function onChange_region(e, { value }) {
        let organization = { ...var_organization };
        organization.province = value;
        set_organization(organization);
    }



    // RENDER APP ======================================================================================================

    return (

        <div className='card rounded-lg shadow-sm' id='organizations_details__tab__details'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Organization details</div>
                </div>
                <div className='card__header__right'>
                    {var_cardstatus === 'DISPLAY' &&
                    <Button className='secondary' onClick={onClick_edit}>Edit</Button>
                    }
                </div>
            </div>

            {var_cardstatus === 'DISPLAY' && var_organization !== null &&
                <div className='card__content'>
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>ID</div>
                        <div className='detailsgroup__value text--sm-regular'>{var_organization.id}</div>
                        <CMP_COPY var_text={var_organization.id} var_type='Button'></CMP_COPY>
                    </div>
                    <FORM_DETAILSGROUP label='Name' value={var_organization.name} />
                    <FORM_DETAILSGROUP label='Address' value={var_organization.address1} />
                    <FORM_DETAILSGROUP label='City' value={var_organization.city} />
                    <FORM_DETAILSGROUP label='Province/State' value={var_organization.province} />
                    <FORM_DETAILSGROUP label='Country' value={var_organization.country} />
                    <FORM_DETAILSGROUP label='Website' value={var_organization.website} />
                    <FORM_DETAILSGROUP label='LinkedIn ID' value={var_organization.linkedin_id} />
                    <FORM_DETAILSGROUP label='Postal/Zip Code' value={var_organization.postal} />
                </div>
            }

            {var_cardstatus === 'EDIT' &&
            <>
                <div className='card__content edit'>
                    {render_mainform()}
                </div>

                <div className='card__footer'>
                    <Button className='primary' onClick={() => onClick_save()}>Save</Button>
                    <Button className='secondary' onClick={() => onClick_cancel()}>Cancel</Button>
                </div>
                <Processing display={var_processing} />
            </>
            }

        </div>
    );

    function render_mainform() {
        return (
            <>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header='Required fields are empty or contain invalid data.'
                    />
                }

                {var_errormessage &&
                    <Message error
                                icon='home'
                                header={var_errormessage.cause}
                                content={var_errormessage.resolution}
                    />
                }

                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>ID</div>
                    <div className='detailsgroup__value text--sm-regular'>{var_organization.id}</div>
                    <CMP_COPY var_text={var_organization.id} var_type='Button'></CMP_COPY>
                </div>

                <FORM_INPUT
                    property='name'
                    label='Name (required)'
                    value={var_organization.name}
                    onChange={onChange_input}
                    placeholder='Organization name'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='address1'
                    label='Address'
                    value={var_organization.address1}
                    onChange={onChange_input}
                    placeholder='Address'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_SELECT
                    property='country'
                    label='Country'
                    value={var_organization.country || ''}
                    onChange={onChange_country}
                    placeholder='Select'
                    options={var_countries}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_SELECT
                    property='province'
                    label='Province/State'
                    value={var_organization.province || ''}
                    onChange={onChange_region}
                    placeholder='Select'
                    options={var_regions}
                    disabled={var_disableregions}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='city'
                    label='City'
                    value={var_organization.city}
                    onChange={onChange_input}
                    placeholder='City'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='postal'
                    label='Postal/Zip code'
                    value={var_organization.postal}
                    onChange={onChange_input}
                    placeholder='Postal/Zip code'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='website'
                    label='Website'
                    value={var_organization.website}
                    onChange={onChange_input}
                    placeholder='URL'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='linkedin_id'
                    label='LinkedIn ID'
                    value={var_organization.linkedin_id}
                    onChange={onChange_input}
                    placeholder='LinkedIn ID'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />
            </>
        );
    }

};