import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Table, { default_populateconfig } from '../../../../components/cmp_table/cmp_table';
import { TOGGLE } from '../../../../components/cmp_form/cmp_form';



export default function TAB_INDIVIDUALS({var_organizationid, assignments}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_archive) {
            set_refreshtable(!var_refreshtable);
            populate_individuals({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_archive]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(populateconfig, archive){

        set_ready(false);
        set_loading(true);
        set_loadingerror(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (archive) {
            set_archive(archive);
        } else {
            archive = var_archive
        }

        try {
            let results = await API_get_individuals(limit, offset, sortby, sortorder, archive, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_individuals(var_populateconfig.maxfilteritems, 0, filtername, sortorder, var_archive, [], filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_individuals(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_individuals(limit, offset, sortby, sortorder, var_archive, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals(limit, offset, sortby, sortorder, archive, filter, filtername) {
        return API.post('alpha-org', '/get-org-individuals/' + var_organizationid,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    archive: archive
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addindividual(){
        navigate('/organizations/' + var_organizationid + '/individuals/add');
    }

    function onClick_individual(var_individualid){
        navigate('/organizations/' + var_organizationid + '/individuals/' + var_individualid);
    }

    function onClick_set_archive(state) {
        set_archive(state);
    }



    // RENDER APP ======================================================================================================

    return (

        <div className='card rounded-lg shadow-sm' id='organizations_details__tab__individuals'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Individuals</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button className='primary' onClick={() => onClick_addindividual()}>Add individual</Button>
                </div>
            </div>

            <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={1} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname='Individuals' downloadfunction={download_individuals}
                    onChange={populate_individuals}>
                <Table.OptionsButtons>
                    <TOGGLE value={var_archive} onChange={onClick_set_archive} options={[{value: 'NO', text: 'Active'}, {value: 'YES', text: 'Archived'}]} />
                </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='name' datatype='text' filtertype='text'>INDIVIDUAL</Table.HeaderCell>
                        <Table.HeaderCell field='email' datatype='text' filtertype='text'>EMAIL</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>ORGANIZATION</Table.HeaderCell>
                        { assignments === 'YES' &&
                            <Table.HeaderCell field='locations' datatype='text' filtertype='text'>ASSIGNMENT</Table.HeaderCell>
                        }
                        <Table.HeaderCell field='permission' datatype='text' filtertype='option'>PERMISSION</Table.HeaderCell>
                        <Table.HeaderCell field='individual_type' datatype='text' filtertype='option'>TYPE</Table.HeaderCell>
                        <Table.HeaderCell field='invitestatus' datatype='text' filtertype='option'>INVITE STATUS</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_individuals.map(item =>
                        <Table.Row key={item.id} onClick={() => onClick_individual(item.id)}>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.email}</Table.Cell>
                            <Table.Cell>{item.organization}</Table.Cell>
                            {assignments === 'YES' &&
                                <Table.Cell>{item.locations}</Table.Cell>
                            }
                            <Table.Cell>
                                <div className={'badge text--sm-medium ' + (    item.permission === 'Superadmin' ? 'badge--solid_blue' :
                                                                item.permission === 'Admin' ? 'badge--solid_green' :
                                                                item.permission === 'Manager' ? 'badge--solid_orange' :
                                                                item.permission === 'Auditor' ? 'badge--yellow' :
                                                                item.permission === 'Global Admin' ? 'badge--solid_purple' :
                                                                item.permission === 'User' && 'badge--solid_gray')}>{item.permission}</div></Table.Cell>
                            <Table.Cell>
                                <div className={'badge text--sm-medium ' + (    item.individual_type === 'USER' ? 'badge--solid_gray' :
                                                                item.individual_type === 'GUEST' && 'badge--solid_pink')}>{item.individual_type}</div></Table.Cell>
                            <Table.Cell>
                                <div className={'badge text--sm-medium ' + (    item.invitestatus === 'ACTIVE' ? 'badge--green' :
                                                                item.invitestatus === 'AUTHENTICATING' ? 'badge--yellow' :
                                                                item.invitestatus === 'DECLINED' ? 'badge--red' :
                                                                item.invitestatus === 'ARCHIVE' ? 'badge--gray' :
                                                                item.invitestatus === 'INVITED' ? 'badge--gray' :
                                                                item.invitestatus === 'PENDING INVITE' ? 'badge--gray' :
                                                                item.invitestatus === 'PENDING' && 'badge--gray')}>{item.invitestatus}</div></Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>

            <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={() => onClick_addindividual()}>Add individual</Button>
            </div>
        </div>
    )

}
